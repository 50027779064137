import type { CarCardProps, CardProps } from 'global/types/props'
import { ButtonColor } from 'global/types/enums'
import { L10N } from 'global/localization'
import { Card } from 'shared/components/card'
import { SubscriptionBadge } from 'shared/components/subscription-badge'
import Icon from 'shared/components/icon'
import { formatDate, isSubscriptionActive } from 'shared/services/utils'

export const CarCard = ({
  car,
  hideImage = false,
  hideSubscription = false,
  onDetail,
  onHistory,
  onSubscribe
}: CarCardProps) => {
  const getActions = (): CardProps['actions'] => {
    const actions: CardProps['actions'] = []

    if (onDetail) actions.push({ name: L10N.LABEL.DETAIL, onClick: () => onDetail(car) })
    if (onHistory) actions.push({ name: L10N.LABEL.HISTORY, onClick: () => onHistory(car) })
    if (onSubscribe)
      actions.push({
        name: L10N.LABEL.SUBSCRIBE_NOW,
        color: ButtonColor.Primary,
        onClick: () => onSubscribe(car)
      })

    return actions
  }

  return (
    <Card
      bannerImage={!hideImage ? (car.imageCar ?? '/unknown-car.png') : undefined}
      actions={getActions()}
    >
      <div className={[!hideImage ? 'text-center' : '', 'mb-3'].join(' ')}>
        <h2 className='font-heading font-medium text-xl'>{car.carModelName ?? '???'}</h2>
        <span className='text-secondary text-sm'>{car.licenseNo}</span>
      </div>

      <div className='flex flex-row mb-3 last:mb-0'>
        <div className='flex-1'>
          <div className='text-neutral text-xs'>{L10N.LABEL.REGISTERED_OWNER}</div>
          <div className='font-heading text-sm'>{car.registeredOwner ?? '-'}</div>
        </div>
        <div className='flex-1'>
          <div className='text-neutral text-xs'>{L10N.LABEL.VIN}</div>
          <div className='font-heading text-sm'>{car.vin ?? '-'}</div>
        </div>
      </div>

      {!hideSubscription && (
        <div className='mb-3 last:mb-0'>
          {isSubscriptionActive(car.status) ? (
            <SubscriptionBadge text={car.statusDescription} subText={formatDate(car.expiredDate)} />
          ) : (
            <SubscriptionBadge text={L10N.LABEL.VEHICLE_HAS_NOT_SUBSCRIBED} color='bg-background' />
          )}
        </div>
      )}

      {car.isRenewal && (
        <div className='flex flex-row items-center px-3 py-1 gap-3 bg-background border border-border'>
          <div>
            <Icon icon='calendar-check' size={24} />
          </div>
          <div className='flex flex-col'>
            <h3 className='text-sm'>{L10N.LABEL.AUTO_RENEWAL}</h3>
            <div className='text-neutral text-xs'>
              {L10N.formatString(L10N.LABEL.AUTO_RENEWAL_DESC, '[DATE]')}
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}
