import { useDispatch } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from './api'
import { layoutReducer } from './slices/layout'
import { orderReducer } from './slices/order'
import { errorToast } from './middlewares/error-toast'

const reducer = combineReducers({
  layout: layoutReducer,
  order: orderReducer,
  [api.reducerPath]: api.reducer
})

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(errorToast)
})

type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()

setupListeners(store.dispatch)
