import { API } from 'global/api'
import type { TrxStatusResponse, TrxTypeResponse } from 'global/types/responses'
import { api, headers } from './api'

const transactionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTrxTypeList: builder.query<TrxTypeResponse[], void>({
      query: () => ({
        url: API.URL_GET_TRX_TYPE_LIST,
        method: 'GET',
        headers: headers()
      })
    }),
    getTrxStatusList: builder.query<TrxStatusResponse[], void>({
      query: () => ({
        url: API.URL_GET_TRX_STATUS_LIST,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const { useLazyGetTrxTypeListQuery, useLazyGetTrxStatusListQuery } = transactionApi
