import type { SubscriptionBadgeProps } from 'global/types/props'
import Icon from 'shared/components/icon'

export const SubscriptionBadge = ({
  icon = 'checkmark-filled',
  text,
  color = 'bg-gradient-primary',
  subText
}: SubscriptionBadgeProps) => (
  <div
    className={`flex flex-row items-center justify-between rounded-full px-2.5 py-1.5 text-sm ${color} ${colorVariants[color].textColor}`}
  >
    <div className='flex flex-row items-center'>
      <Icon className={`mr-2 ${colorVariants[color].iconColor}`} icon={icon} size={16} />
      <div className='line-clamp-1'>{text}</div>
    </div>

    <div>{subText}</div>
  </div>
)

const colorVariants: ColorVariantProps = {
  'bg-background': {
    textColor: 'text-black',
    iconColor: 'text-light-neutral'
  },
  'bg-gradient-primary': {
    textColor: 'text-white',
    iconColor: 'text-white'
  }
}

interface ColorVariantProps {
  [key: string]: { textColor: string; iconColor: string }
}
