import type { SelectPlanContentProps } from 'global/types/props'
import { PillNav } from 'shared/components/pill-nav'
import { SubscriptionPackage } from 'shared/components/subscription-package'
import { CarCard } from 'shared/components/car-card'
import { Spinner } from 'shared/components/spinner'
import { Empty } from 'shared/components/empty'
import { L10N } from 'global/localization'

export const SelectPlanContent = ({
  car,
  isLoading,
  subsPackageList,
  periodList,
  period,
  setPeriod,
  onSelectSubscriptionPackage
}: SelectPlanContentProps) => (
  <>
    <CarCard car={car} hideImage />

    {/* <Card>
        <div className='flex flex-row items-center justify-between mb-3'>
          <h2 className='font-heading font-medium text-xl'>{car.carModelName}</h2>
          <span className='text-secondary text-sm'>{car.licenseNo}</span>
        </div>

        <div className='flex flex-row mb-3'>
          <div className='flex-1'>
            <div className='text-neutral text-xs'>{'Registered Owner'}</div>
            <div className='font-heading text-sm'>{car.carModelName}</div>
          </div>
          <div className='flex-1'>
            <div className='text-neutral text-xs'>{'VIN'}</div>
            <div className='font-heading text-sm'>{car.vinNumber}</div>
          </div>
        </div>

        <SubscriptionBadge
          text={
            car.serviceStartDate ? undefined : 'Vehicle is not yet subscribed to bluelink services'
          }
          subText={car.serviceStartDate ? formatDate(car.serviceStartDate) : undefined}
          color={car.serviceStartDate ? undefined : 'bg-background'}
        />
      </Card> */}

    {isLoading ? (
      <div className='flex items-center justify-center'>
        <Spinner />
      </div>
    ) : subsPackageList?.length ? (
      <>
        <div className='mb-4'>
          <h2 className='font-heading font-medium text-lg text-center'>{L10N.LABEL.CHOOSE_PLAN}</h2>

          <div className='text-neutral text-sm text-center'>
            {L10N.LABEL.UNLOCK_BLUELINK_FEATURES}
          </div>
        </div>

        {periodList?.length && (
          <div className='text-center mb-4'>
            <PillNav
              items={periodList.map((p) => ({ key: p.value, value: p.text }))}
              selected={period}
              onSelect={setPeriod}
            />
          </div>
        )}

        {subsPackageList?.map((subsPackage, id) => (
          <SubscriptionPackage
            key={id + 1}
            subsPackage={subsPackage}
            onSelect={onSelectSubscriptionPackage}
          />
        ))}
      </>
    ) : (
      <Empty small />
    )}
  </>
)
