import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { LayoutInitialState } from 'global/types/states'
import type { RootState } from 'store/store'

const initialState: LayoutInitialState = {
  contentHeight: 0,
  noPadding: false
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setContentHeight: (state, action: PayloadAction<number>) => {
      state.contentHeight = action.payload
    },
    setNoPadding: (state, action: PayloadAction<boolean>) => {
      state.noPadding = action.payload
    }
  }
})

export const { setContentHeight, setNoPadding } = layoutSlice.actions

export const getLayoutState = (state: RootState) => state.layout

export const layoutReducer = layoutSlice.reducer
