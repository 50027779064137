import type { PillNavProps } from 'global/types/props'

export const PillNav = ({ items, selected, onSelect }: PillNavProps) => (
  <div className='inline-flex flex-row bg-white font-heading text-neutral rounded-full p-1'>
    {items.map((item, id) => (
      <button
        key={id + 1}
        className={[
          'px-4 py-1.5 rounded-full text-sm text-center',
          selected === item.key ? 'bg-secondary text-white' : 'bg-transparent'
        ].join(' ')}
        type='button'
        onClick={() => onSelect(item.key)}
      >
        {item.value}
      </button>
    ))}
  </div>
)
