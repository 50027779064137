import type { Middleware } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { toast } from 'react-toastify'
import type { BaseErrorResponse } from 'global/types/responses'

export const errorToast: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const fallbackMessage = action.error.message

    const error = (action.payload as FetchBaseQueryError)?.data as BaseErrorResponse | undefined

    toast.dismiss()

    console.warn('❌ Oops, we got an error!', action)
    toast.error(error?.errorMessage ?? fallbackMessage)
  }

  return next(action)
}
