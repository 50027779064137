import type { ResponsiveType } from 'react-multi-carousel';
import ReactMultiCarousel from 'react-multi-carousel'
import type { CarouselProps } from 'global/types/props'

export const Carousel = ({ children }: CarouselProps) => (
  <div className='relative pb-7'>
    <ReactMultiCarousel
      responsive={responsive}
      itemClass='pl-4 last:pr-4'
      arrows={false}
      partialVisible
      showDots
      renderDotsOutside
    >
      {children}
    </ReactMultiCarousel>
  </div>
)

const responsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40 // The amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1024, min: 576 },
    items: 1,
    partialVisibilityGutter: 40 // The amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
    partialVisibilityGutter: 40 // The amount of px that should be visible.
  }
}
