import { API } from 'global/api'
import type { CheckPromoCodeParam } from 'global/types/params'
import type { CheckPromoCodeResponse } from 'global/types/responses'
import { api, headers } from './api'

const promoCodeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    checkPromoCode: builder.mutation<CheckPromoCodeResponse, CheckPromoCodeParam>({
      query: (param) => {
        const qParams = [
          param.planId ? `planId=${param.planId}` : undefined,
          param.price ? `price=${param.price}` : undefined
        ]
          .filter(Boolean)
          .join('&')

        return {
          url: `${API.URL_CHECK_PROMO_CODE.replace('{code}', param.code)}?${qParams}`,
          method: 'GET',
          headers: headers()
        }
      }
    })
  })
})

export const { useCheckPromoCodeMutation } = promoCodeApi
