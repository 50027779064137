import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonColor, LayoutType } from 'global/types/enums'
import { useAppDispatch } from 'store/store'
import { useCheckPromoCodeMutation } from 'store/promo-code'
import { setPromo } from 'store/slices/order'
import { Button } from 'shared/components/button'
import { Input } from 'shared/components/input'
import { Layout } from 'shared/components/layout'
import { formatCurrency, useBack } from 'shared/services/utils'
import { EnterPromoFooter } from './footer'

export const EnterPromo = () => {
  const { id } = useParams()

  const { navigateBack } = useBack()

  const dispatch = useAppDispatch()

  const [checkPromoCode, checkPromoCodeResult] = useCheckPromoCodeMutation()

  const [code, setCode] = useState<string>('')

  const [message, setMessage] = useState<React.ReactNode>(undefined)

  const [messageColor, setMessageColor] = useState<string>('text-black')

  useEffect(() => {
    if (!checkPromoCodeResult.isSuccess || !checkPromoCodeResult.data) return

    setMessageColor('text-danger')

    setMessage(
      <>
        Promo code applied! You save{' '}
        <span className='font-medium'>{formatCurrency(checkPromoCodeResult.data.value)}</span>.
      </>
    )

    // <>
    //   Sorry, the code you've entered is fully redeemed or invalid.
    // </>

    // <>
    //   The promo code is expired.
    // </>
  }, [checkPromoCodeResult.data])

  const inputCode = (text: string) => {
    setCode(text.toUpperCase())
  }

  const applyCode = () => checkPromoCode({ code, planId: Number(id) })

  const useCode = () => {
    if (!checkPromoCodeResult.data) return

    dispatch(
      setPromo({
        promoCode: checkPromoCodeResult.data.promoCode,
        promoAmount: checkPromoCodeResult.data.value
      })
    )

    navigateBack?.()
  }

  return (
    <Layout
      title='Enter Promo Code'
      type={LayoutType.HeaderFooter}
      footerComponent={
        <EnterPromoFooter
          total={checkPromoCodeResult.data?.value ?? 0}
          canSubmit={Boolean(checkPromoCodeResult.data?.promoId)}
          onSubmit={useCode}
        />
      }
    >
      <div className='animate-fadeIn'>
        <div className='mb-4 font-medium'>{'Enter promo code'}</div>

        <div className='flex flex-row gap-3 items-center mb-2'>
          <div className='flex-1'>
            <Input
              value={code}
              icon='coupon'
              actionIcon={code && 'close-filled'}
              onChange={inputCode}
              onAction={() => setCode('')}
            />
          </div>
          <Button label='Apply' color={ButtonColor.Primary} disabled={!code} onClick={applyCode} />
        </div>

        {message && <div className={`text-sm ${messageColor}`}>{message}</div>}
      </div>
    </Layout>
  )
}
