import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ReactModal from 'react-modal'
import { Protected } from 'shared/components/protected'
import { DevCenter } from 'pages/dev-center/page'
import { EnterPromo } from 'pages/enter-promo/page'
import { Help } from 'pages/help/page'
import { Home } from 'pages/home/page'
import { MyCar } from 'pages/my-car/page'
import { OrderDetail } from 'pages/order-detail/page'
import { Payment } from 'pages/payment/page'
import { PrivacyPolicy } from 'pages/privacy-policy/page'
import { Result } from 'pages/result/page'
import { SelectPayment } from 'pages/select-payment/page'
import { SelectPlan } from 'pages/select-plan/page'
import { Settings } from 'pages/settings/page'
import { Tnc } from 'pages/tnc/page'
import { TrxHistory } from 'pages/trx-history/page'
import { TrxHistoryDetail } from 'pages/trx-history/detail/page'

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

ReactModal.setAppElement('#root')

const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<Protected />}>
      <Route path='/home' element={<Home />} />
      <Route path='/my-car' element={<MyCar />} />
      <Route path='/subscription-plan' element={<SelectPlan />} />
      <Route path='/subscription-plan/order/:id' element={<OrderDetail />} />
      <Route path='/subscription-plan/order/promo/:id' element={<EnterPromo />} />
      <Route path='/subscription-plan/order/payment/:id' element={<SelectPayment />} />
      <Route path='/payment' element={<Payment />} />
      <Route path='/result' element={<Result />} />
      <Route path='/tnc' element={<Tnc />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/transaction-history' element={<TrxHistory />} />
      <Route path='/transaction-history/:id' element={<TrxHistoryDetail />} />
      <Route path='/help' element={<Help />} />
      <Route path='/settings' element={<Settings />} />
    </Route>

    <Route path='/dev-center' element={<DevCenter />} />
    {/* <Route path='*' element={<NoRoute />} /> */}
  </Routes>
)

export default App
