import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import type { SubscriptionPackageResponse } from 'global/types/responses'
import { useAppDispatch } from 'store/store'
import {
  useGetActiveCarQuery,
  useGetPackageListQuery,
  useGetPeriodListQuery
} from 'store/subscription'
import { resetOrder } from 'store/slices/order'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { SelectPlanContent } from './content'

export const SelectPlan = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const activeCarResult = useGetActiveCarQuery(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')

  const packageListResult = useGetPackageListQuery(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')

  console.log('packa', packageListResult)

  const periodListResult = useGetPeriodListQuery()

  const [period, setPeriod] = useState<string>('')

  useEffect(() => {
    if (!periodListResult.isSuccess || !periodListResult.data?.length) return

    setPeriod(periodListResult.data[0].value)
  }, [periodListResult.isSuccess])

  const selectSubsPackage = (subsPackage: SubscriptionPackageResponse) => {
    if (!subsPackage.id) return

    dispatch(resetOrder())
    navigate(`order/${subsPackage.id}`)
  }

  return (
    <Layout title={L10N.LABEL.RENEW_PLAN}>
      <DataHandler {...activeCarResult}>
        {activeCarResult.data && (
          <SelectPlanContent
            car={activeCarResult.data}
            isLoading={packageListResult.isLoading}
            subsPackageList={packageListResult.data}
            periodList={periodListResult.data}
            period={period}
            setPeriod={setPeriod}
            onSelectSubscriptionPackage={selectSubsPackage}
          />
        )}
      </DataHandler>
    </Layout>
  )
}
