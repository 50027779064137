export const API = {
  // Dummy
  URL_GET_TRX_TYPE_LIST: '/core/api/v1/transactionhistory/gettype',
  URL_GET_TRX_STATUS_LIST: '/core/api/v1/transactionhistory/getstatus/0',
  URL_GET_CAR_LIST: '/core/api/v1/mycar/getmycarlist',
  URL_GET_CAR_DETAIL: '/core/api/v1/mycar/getcardetail',
  URL_GET_CONTACT_US: '/core/api/v1/contactus',

  // Subscription
  URL_GET_ACTIVE_CAR: '/core/api/v1/subscription/bluelink/{vin}/status/test',
  URL_GET_ACTIVE_PACKAGE: '/core/api/v1/subscription/bluelink/{vin}/active/test',
  URL_GET_PACKAGE_LIST: '/core/api/v1/subscription/bluelink/{vin}',
  URL_GET_ORDER_DETAIL: '/core/api/v1/subscription/bluelink/order/{planId}',
  URL_GET_PAYMENT_METHOD_LIST: '/core/api/v1/subscription/paymentmethod/ddl',
  URL_GET_TRX_HISTORY: '/core/api/v1/subscription/bluelink/history',
  URL_GET_TRX_HISTORY_DETAIL: '/core/api/v1/subscription/bluelink/history/{id}',
  URL_GET_PERIOD_LIST: '/core/api/v1/subscription/duration/ddl',
  URL_STOP_RENEWAL_STATUS: '/core/api/v1/subscription/bluelink/{vin}/renewal/inactive/test',

  // Promo code
  URL_CHECK_PROMO_CODE: '/core/api/v1/promocode/{code}/check',

  // Copyright
  URL_GET_COPYRIGHT: '/core/api/v1/copyright',
  URL_GET_LOCALIZATION: '/core/api/v1/copyright/language/dictionary',

  // Language
  URL_GET_LANGUAGE_LIST: '/core/api/v1/language/ddl',

  // Session
  URL_GET_SESSION: '/core/api/v1/session',
  URL_GET_REFRESH_SESSION: '/core/api/v1/session/refresh'
} as const
