import moment from 'moment'
import type { TrxHistoryDetailContentProps } from 'global/types/props'
import { Card } from 'shared/components/card'
import { Counter } from 'shared/components/counter'
import Icon from 'shared/components/icon'
import { formatCurrency, formatDate, formatDateTime } from 'shared/services/utils'
import { StatusBadge } from 'shared/components/status-badge'

export const TrxHistoryDetailContent = ({ trxDetail, onExpired }: TrxHistoryDetailContentProps) => (
  <>
    <h2 className='font-heading font-medium mb-4'>Payment Information</h2>

    <Card>
      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-neutral text-sm'>Status</div>
        <div className='font-medium text-sm'>
          <StatusBadge status={trxDetail.paymentInformation.status} />
        </div>
      </div>

      <div className='flex flex-col gap-2 items-center p-2 bg-background border border-border mb-3'>
        <div className='flex flex-col items-center text-sm mb-3'>
          <div>Expired in</div>
          <div className='font-medium'>
            {formatDateTime(trxDetail.paymentInformation.expiredIn, true)}
          </div>
        </div>
        <Counter
          type='count-down'
          date={moment(trxDetail.paymentInformation.expiredIn).add({ minutes: 30 }).toDate()}
          showSublabel
          onTimeUp={onExpired}
        />
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-neutral text-sm'>Invoice ID</div>
        <div className='font-medium text-sm'>{trxDetail.paymentInformation.transactionID}</div>
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-neutral text-sm'>Transaction date</div>
        <div className='font-medium text-sm'>
          {formatDateTime(trxDetail.paymentInformation.transactionDate)}
        </div>
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-neutral text-sm'>Payment method</div>
        <div className='font-medium text-sm'>{trxDetail.paymentInformation.paymentMetodeDesc}</div>
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-neutral text-sm'>Total payment</div>
        <div className='font-medium text-sm'>
          {formatCurrency(trxDetail.paymentInformation.grandTotal)}
        </div>
      </div>
    </Card>

    <h2 className='font-heading font-medium mb-4'>Purchase Detail</h2>

    <Card>
      <div className='flex flex-row items-center mb-4'>
        <img
          src={trxDetail.purchaseDetail.image}
          alt='subscription-package'
          className='size-11 rounded-full mr-4'
        />
        <div className='flex flex-col'>
          <div className='text-sm'>Plan</div>
          <div className='font-medium text-sm'>{trxDetail.purchaseDetail.name} - Yearly</div>
        </div>
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-sm text-neutral'>Subscription end date</div>
        <div className='font-medium text-sm text-right'>
          {formatDate(trxDetail.paymentInformation.transactionDate)}
        </div>
      </div>

      <div className='flex flex-row gap-2 items-center p-2 bg-background border border-border'>
        <Icon icon='calendar' size={24} />

        <div className='flex-1'>
          <h3 className='text-sm'>Auto Renewal</h3>
          <div className='text-neutral text-xs'>
            Starting from {formatDate(trxDetail.paymentInformation.expiredIn)}, you&apos;ll be
            charged automatically every year until you cancel.
          </div>
        </div>
      </div>
    </Card>

    <h2 className='font-heading font-medium mb-4'>Detail Payment</h2>

    <Card>
      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-sm'>Price</div>
        <div className='font-medium text-sm text-right'>
          {formatCurrency(trxDetail.detailInformation.subTotal)}
        </div>
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-sm'>Discount</div>
        <div className='font-medium text-sm text-right'>
          {formatCurrency(trxDetail.detailInformation.deliveryCharge)}
        </div>
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-sm'>VAT</div>
        <div className='font-medium text-sm text-right'>
          {formatCurrency(trxDetail.detailInformation.tax)}
        </div>
      </div>

      <hr className='border border-border mb-3' />

      <div className='flex flex-row items-center justify-between'>
        <div className='font-bold text-sm'>Total (inlcuding tax)</div>
        <div className='font-bold text-sm text-right'>
          {formatCurrency(trxDetail.detailInformation.grandTotal)}
        </div>
      </div>
    </Card>
  </>
)
