import { useEffect, useState } from 'react'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { CONSTANT } from 'global/constant'
import { SubscriptionStatus } from 'global/types/enums'

export const useDebounce = (value?: string, delay = 1000) => {
  const [debounceValue, setDebounceValue] = useState<string | undefined>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value)
    }, delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debounceValue
}

export const useBack = () => {
  const location = useLocation()

  const navigate = useNavigate()

  const [backRoute, setBackRoute] = useState<string>()

  useEffect(() => {
    /**
     * @example
     * location.pathname = '/path/to/some/page'
     *
     * Result:
     * [
     *   '',
     *   'path',
     *   'to',
     *   'some',
     *   'page'
     * ]
     */
    const urlSegments = location.pathname.split('/')

    let route: string | undefined = undefined
    if (['promo', 'payment'].includes(urlSegments[3])) {
      route = urlSegments.filter((s) => !['promo', 'payment'].includes(s)).join('/')
    } else if (['order'].includes(urlSegments[2])) {
      route = '/subscription-plan'
    } else if (urlSegments[1] === 'subscription-plan') {
      route = '/home'
    }

    setBackRoute(route)
  }, [])

  return {
    route: backRoute,
    navigateBack: backRoute ? () => navigate(backRoute) : undefined
  }
}

export const isSubscriptionActive = (status: SubscriptionStatus) =>
  [SubscriptionStatus.InVehicle, SubscriptionStatus.FullEnrollment].includes(status)

export const formatCurrency = (value: number, locale?: string): string => {
  let format = ''
  let localeString = locale

  switch (locale) {
    default:
      format = CONSTANT.ID_CURRENCY_FORMAT
      localeString = undefined
  }

  return format.replace('#', value.toLocaleString(localeString))
}

export const formatDate = (value: moment.MomentInput, long = false): string =>
  moment(value).format(long ? CONSTANT.LONG_DATE_FORMAT : CONSTANT.SHORT_DATE_FORMAT)

export const formatDateTime = (value: moment.MomentInput, long = false): string =>
  moment(value).format(long ? CONSTANT.LONG_DATETIME_FORMAT : CONSTANT.SHORT_DATETIME_FORMAT)
