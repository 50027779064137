import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LayoutType, ResultType } from 'global/types/enums'
import { Layout } from 'shared/components/layout'
import { formatDate } from 'shared/services/utils'
import { ResultContent } from 'shared/components/result-content'

const TYPE_QPARAM_KEY = 't'

const DATA_QPARAM_KEY = 'd'

export const Result = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const [type, setType] = useState<ResultType>()

  const [data, setData] = useState<ResultData>()

  useEffect(() => {
    setType(Number(searchParams.get(TYPE_QPARAM_KEY)))

    const data = searchParams.get(DATA_QPARAM_KEY)
    if (data) setData(JSON.parse(data))
  }, [searchParams])

  const getContent = () => {
    switch (type) {
      case ResultType.AutoRenewalStop:
        return (
          <ResultContent
            image='/stop-payment.png'
            title='Your auto-renewal has been canceled'
            desc={`Your subscription will remain active until ${formatDate(data?.date)}. You won't be charged again unless you choose to reactivate your subscription.`}
            actionText='Back to Homepage'
            onAction={() => navigate('/home')}
          />
        )

      case ResultType.PaymentSuccess:
        return (
          <ResultContent
            image='/payment-success.png'
            title='Payment Successful!'
            desc='Thank you for subscribing to Bluelink.'
            actionText='Back to Bluelink'
            onAction={() => navigate('/home')}
          />
        )

      case ResultType.PaymentFailed:
        return (
          <ResultContent
            image='/payment-failed.png'
            title='Payment Failed'
            desc='Unfortunately, we were unable to process your payment. Please check your payment details and try again.'
            actionText='Back to Bluelink'
            onAction={() => navigate('/home')}
          />
        )

      default:
        return null
    }
  }

  return (
    <Layout title='Result' type={LayoutType.Fullscreen}>
      <div className='h-full animate-fadeIn flex items-center justify-center'>{getContent()}</div>
    </Layout>
  )
}

interface ResultData {
  [key: string]: string
}
