import { useState } from 'react'
import { type SelectProps } from 'global/types/props'
import Icon from 'shared/components/icon'
import { ActionSheet } from 'shared/components/action-sheet'
import { List } from 'shared/components/list'
import { SelectType } from 'global/types/enums'

export const Select = ({
  label,
  type = SelectType.Picker,
  options,
  value,
  onSelect
}: SelectProps) => {
  const [showPicker, setShowPicker] = useState<boolean>(false)

  switch (type) {
    case SelectType.Pills:
      return (
        <>
          {label && <div className='mb-1'>{label}</div>}
          <div className='flex flex-wrap gap-2 mb-4'>
            {options.map((item, id) => (
              <button
                key={id + 1}
                className={[
                  'px-2 py-1 text-sm',
                  item.key === value ? 'bg-secondary text-white' : 'bg-background text-black'
                ].join(' ')}
                type='button'
                onClick={() => {
                  // setSelectedOption(item.key)
                  onSelect?.(item.key)
                }}
              >
                {item.value}
              </button>
            ))}
          </div>
        </>
      )

    default:
      return (
        <>
          {label && <div className='mb-1'>{label}</div>}
          <button
            className='flex flex-row items-center justify-between w-full px-3 mb-4 bg-background'
            type='button'
            onClick={() => setShowPicker(true)}
          >
            <div className='flex-1 flex items-center h-12'>
              {options.find((o) => o.key === value)?.value}
            </div>

            <Icon icon='chevron-down' size={20} className='ml-3 text-neutral' />
          </button>

          <ActionSheet title={label} open={showPicker} onClose={() => setShowPicker(false)}>
            <List
              list={options}
              selectedItem={value}
              onClick={(option) => {
                setShowPicker(false)

                onSelect?.(option)
              }}
            />
          </ActionSheet>
        </>
      )
  }
}
