import { API } from 'global/api'
import type { SessionResponse } from 'global/types/responses'
import type { CreateSessionParam } from 'global/types/params'
import { api, headers } from './api'

const sessionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createSession: builder.mutation<SessionResponse, CreateSessionParam>({
      query: (body) => ({
        url: API.URL_GET_SESSION,
        method: 'POST',
        headers: headers(),
        body
      })
    })
  })
})

export const { useCreateSessionMutation } = sessionApi
