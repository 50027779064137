import { API } from 'global/api'
import type {
  ActiveCarResponse,
  PaginatedResponse,
  SubscriptionPackageResponse,
  DdlResponse,
  TrxHistoryListItemResponse,
  TrxHistoryDetailResponse,
  OrderDetailResponse
} from 'global/types/responses'
import type { OrderDetailParam, TrxHistoryParam } from 'global/types/params'
import { CONSTANT } from 'global/constant'
import { api, headers } from './api'

const subscriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActiveCar: builder.query<ActiveCarResponse, string>({
      query: (vin) => ({
        url: API.URL_GET_ACTIVE_CAR.replace('{vin}', vin),
        method: 'GET',
        headers: headers()
      })
    }),
    getActivePackage: builder.query<SubscriptionPackageResponse[], string>({
      query: (vin) => ({
        url: API.URL_GET_ACTIVE_PACKAGE.replace('{vin}', vin),
        method: 'GET',
        headers: headers()
      })
    }),
    getPackageList: builder.query<SubscriptionPackageResponse[], string>({
      query: (vin) => ({
        url: API.URL_GET_PACKAGE_LIST.replace('{vin}', vin),
        method: 'GET',
        headers: headers()
      }),
      transformResponse: (result: PaginatedResponse<SubscriptionPackageResponse>) => result.data
    }),
    getOrderDetail: builder.query<OrderDetailResponse, OrderDetailParam>({
      query: (param) => {
        const qParams: string = [
          `vin=${param.vin}`,
          param.promoCode ? `promoCode=${param.promoCode}` : ''
        ]
          .filter(Boolean)
          .join('&')

        return {
          url: `${API.URL_GET_ORDER_DETAIL.replace('{planId}', param.planId)}?${qParams}`,
          method: 'GET',
          headers: headers()
        }
      }
    }),
    getPaymentMethodList: builder.query<DdlResponse[], void>({
      query: () => ({
        url: API.URL_GET_PAYMENT_METHOD_LIST,
        method: 'GET',
        headers: headers()
      })
    }),
    getPeriodList: builder.query<DdlResponse[], void>({
      query: () => ({
        url: API.URL_GET_PERIOD_LIST,
        method: 'GET',
        headers: headers()
      })
    }),
    stopRenewalStatus: builder.mutation<unknown, string>({
      query: (vin) => ({
        url: API.URL_STOP_RENEWAL_STATUS.replace('{vin}', vin),
        method: 'POST',
        headers: headers()
      })
    }),

    // Transaction.
    getTrxHistory: builder.query<PaginatedResponse<TrxHistoryListItemResponse>, TrxHistoryParam>({
      query: (param) => {
        const qParams: string = [
          `start=${param.start}`,
          `length=${CONSTANT.ITEMS_PER_PAGE}`,
          `search=${param.search}`,
          param.sortBy ? `sort=${param.sortBy}` : ''
        ]
          .filter(Boolean)
          .join('&')

        return {
          url: `${API.URL_GET_TRX_HISTORY}?${qParams}`,
          method: 'GET',
          headers: headers()
        }
      }
    }),
    getTrxHistoryDetail: builder.query<TrxHistoryDetailResponse, string>({
      query: (id) => ({
        url: API.URL_GET_TRX_HISTORY_DETAIL.replace('{id}', id),
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetActiveCarQuery,
  useLazyGetActivePackageQuery,
  useGetPackageListQuery,
  useLazyGetPackageListQuery,
  useLazyGetOrderDetailQuery,
  useGetPaymentMethodListQuery,
  useGetPeriodListQuery,
  useStopRenewalStatusMutation,
  useLazyGetTrxHistoryQuery,
  useGetTrxHistoryDetailQuery
} = subscriptionApi
