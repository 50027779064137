import { L10N } from 'global/localization'
import { ButtonColor } from 'global/types/enums'
import type { HomeContentProps } from 'global/types/props'
import { Button } from 'shared/components/button'
import { CarCard } from 'shared/components/car-card'
import { Carousel } from 'shared/components/carousel'
import { Empty } from 'shared/components/empty'
import { Spinner } from 'shared/components/spinner'
import { SubscriptionPackage } from 'shared/components/subscription-package'

export const HomeContent = ({
  car,
  isLoading,
  activePackages,
  onUpgradePlan,
  onStopAutoRenewal
}: HomeContentProps) => (
  <>
    <CarCard car={car} />

    <h2 className='font-heading font-medium mb-4'>{L10N.LABEL.ACTIVE_PACKAGE}</h2>

    {isLoading ? (
      <div className='flex items-center justify-center'>
        <Spinner />
      </div>
    ) : activePackages?.length ? (
      <>
        {activePackages.length === 1 ? (
          <SubscriptionPackage subsPackage={activePackages[0]} isActive />
        ) : (
          <div className='-mx-4 mb-4'>
            <Carousel>
              {activePackages.map((subsPackage, id) => (
                <SubscriptionPackage key={id + 1} subsPackage={subsPackage} isActive />
              ))}
            </Carousel>
          </div>
        )}

        <div className='grid'>
          {car.isRenewal ? (
            <Button
              label={L10N.LABEL.STOP_AUTO_RENEWAL}
              color={ButtonColor.Transparent}
              onClick={onStopAutoRenewal}
            />
          ) : (
            <Button label={L10N.LABEL.RENEW_PLAN} color={ButtonColor.Primary} onClick={onUpgradePlan} />
          )}
        </div>
      </>
    ) : (
      <Empty small />
    )}
  </>
)
