import type { LocalizedStringsMethods } from 'react-localization'
import LocalizedStrings from 'react-localization'

interface LocalizedValues extends LocalizedStringsMethods {
  LABEL: {
    [key: string]: string
  }
  MESSAGE: {
    [key: string]: string
  }
}

export const L10N: LocalizedValues = new LocalizedStrings({
  'en-US': {
    LABEL: {},
    MESSAGE: {}
  },
  ID: {
    LABEL: {},
    MESSAGE: {}
  }
})
