import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'shared/components/layout'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import { LayoutType, ResultType } from 'global/types/enums'
import type { SubscriptionPackageResponse } from 'global/types/responses'
import {
  useGetActiveCarQuery,
  useGetPeriodListQuery,
  useLazyGetActivePackageQuery,
  useLazyGetPackageListQuery,
  useStopRenewalStatusMutation
} from 'store/subscription'
import { DataHandler } from 'shared/components/data-handler'
import { ConfirmModal } from 'shared/components/confirm-modal'
import { Empty } from 'shared/components/empty'
import { formatDate, isSubscriptionActive } from 'shared/services/utils'
import { SelectPlanContent } from 'pages/select-plan/content'
import { HomeContent } from './content'

export const Home = () => {
  const navigate = useNavigate()

  const activeCarResult = useGetActiveCarQuery(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')

  const [getActivePackage, activePackageResult] = useLazyGetActivePackageQuery()

  const [getPackageList, packageListResult] = useLazyGetPackageListQuery()

  const periodListResult = useGetPeriodListQuery()

  const [stopRenewalStatus, stopRenewalStatusResult] = useStopRenewalStatusMutation()

  const [period, setPeriod] = useState<string>('')

  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  useEffect(() => {
    if (!activeCarResult.data) return

    if (isSubscriptionActive(activeCarResult.data.status)) {
      getActivePackage(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')
    } else {
      getPackageList(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')
    }
  }, [activeCarResult.data])

  useEffect(() => {
    if (stopRenewalStatusResult.isSuccess) {
      const data = {
        date: '2025-01-20'
      }

      navigate(`/result?t=${ResultType.AutoRenewalStop}&d=${JSON.stringify(data)}`)
    }
  }, [stopRenewalStatusResult.data])

  const selectSubsPackage = (subsPackage: SubscriptionPackageResponse) => {
    if (!subsPackage.id) return

    // dispatch(resetOrder())
    // navigate(`order/${subsPackage.id}`)
  }

  const upgradePlan = () => {
    navigate('/subscription-plan')
  }

  const stopAutoRenewal = () => {
    stopRenewalStatus(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')
  }

  return (
    <Layout title={L10N.LABEL.HOME} type={LayoutType.TabFooter}>
      <DataHandler {...activeCarResult}>
        {activeCarResult.data ? (
          <>
            {isSubscriptionActive(activeCarResult.data.status) ? (
              <HomeContent
                car={activeCarResult.data}
                isLoading={activePackageResult.isLoading}
                activePackages={activePackageResult.data}
                onUpgradePlan={upgradePlan}
                onStopAutoRenewal={() => setShowConfirm(true)}
              />
            ) : (
              <SelectPlanContent
                car={activeCarResult.data}
                isLoading={packageListResult.isLoading}
                subsPackageList={packageListResult.data}
                periodList={periodListResult.data}
                period={period}
                setPeriod={setPeriod}
                onSelectSubscriptionPackage={selectSubsPackage}
              />
            )}
          </>
        ) : (
          <Empty />
        )}
      </DataHandler>

      {activeCarResult.data && (
        <ConfirmModal
          title={L10N.LABEL.CONFIRMATION}
          desc={L10N.formatString(
            L10N.MESSAGE.STOP_RENEWAL_DESC,
            formatDate(activeCarResult.data.expiredDate, true)
          )}
          confirmText={L10N.LABEL.YES_STOP}
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
          onConfirm={stopAutoRenewal}
        />
      )}
    </Layout>
  )
}
