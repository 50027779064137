import { LayoutType } from 'global/types/enums'
import { Layout } from 'shared/components/layout'

export const Payment = () => (
  <Layout title='Payment' type={LayoutType.Header} noPadding>
    <iframe
      title='Xendit Payment'
      src='https://checkout-staging.xendit.co/web/675a9b5eb59a8185eaf49d0f'
      className='w-full h-full'
    />
  </Layout>
)
