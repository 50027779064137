import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CheckboxType } from 'global/types/enums'
import type { OrderDetailContentProps } from 'global/types/props'
import { useAppDispatch } from 'store/store'
import {
  getOrderState,
  setAgreePrivacyPolicy,
  setAgreeTnc,
  setEnableAutoRenewal
} from 'store/slices/order'
import { CarCard } from 'shared/components/car-card'
import { Card } from 'shared/components/card'
import { Checkbox } from 'shared/components/checkbox'
import { ConfirmModal } from 'shared/components/confirm-modal'
import Icon from 'shared/components/icon'
import { formatCurrency, formatDate } from 'shared/services/utils'
import { Empty } from 'shared/components/empty'
import { Spinner } from 'shared/components/spinner'

export const OrderDetailContent = ({
  car,
  isLoading = false,
  orderDetail,
  onEnterPromo,
  onClickTnc,
  onClickPolicy
}: OrderDetailContentProps) => {
  const dispatch = useAppDispatch()

  const order = useSelector(getOrderState)

  const [showAutoRenewalModal, setShowAutoRenewalModal] = useState<boolean>(false)

  const toggleAutoRenewal = () => {
    const newValue = !order.isEnableAutoRenewal

    dispatch(setEnableAutoRenewal(newValue))
  }

  const toggleAgreeTnc = () => {
    const newValue = !order.isAgreeTnc

    if (newValue && orderDetail?.isFirstSubscription) {
      onClickTnc?.(true)
      return
    }

    dispatch(setAgreeTnc(newValue))
  }

  const toggleAgreePrivacyPolicy = () => {
    const newValue = !order.isAgreePrivacyPolicy

    if (newValue && orderDetail?.isFirstSubscription) {
      onClickPolicy?.(true)
      return
    }

    dispatch(setAgreePrivacyPolicy(newValue))
  }

  return (
    <>
      <CarCard car={car} />

      {isLoading ? (
        <div className='flex items-center justify-center'>
          <Spinner />
        </div>
      ) : (
        <>
          {orderDetail ? (
            <>
              <h2 className='font-heading font-medium mb-4'>Subscription Plan Detail</h2>

              <Card>
                <div className='flex flex-row items-center mb-4'>
                  <img
                    src={orderDetail.iconUrl || 'https://dummyimage.com/32'}
                    alt='subscription-package'
                    className='size-11 rounded-full mr-4'
                  />
                  <div className='flex flex-col'>
                    <div className='text-sm'>Plan</div>
                    <div className='font-medium text-sm'>
                      {orderDetail.name} - {orderDetail.duration}
                    </div>
                  </div>
                </div>

                <div className='flex flex-row items-center justify-between'>
                  <div className='text-sm text-neutral'>Subscription end date</div>
                  <div className='font-medium text-sm text-right'>
                    {formatDate(orderDetail.validUntil)}
                  </div>
                </div>
              </Card>

              <Card onClick={onEnterPromo}>
                <div className='flex flex-row items-center justify-between'>
                  <div className='flex flex-row items-center'>
                    <Icon icon='electric-car' size={24} className='mr-2' />
                    <div>
                      {order.promoCode ? `Promo code: ${order.promoCode}` : 'Enter promo code'}
                    </div>
                  </div>
                  <Icon icon={order.promoCode ? 'chevron-right' : 'add'} size={24} />
                </div>
              </Card>

              <h2 className='font-heading font-medium mb-4'>Payment Details</h2>

              <Card>
                <div className='flex flex-row items-center justify-between mb-3'>
                  <div className='text-sm'>Price</div>
                  <div className='font-medium text-sm text-right'>
                    {formatCurrency(orderDetail.paymentFee.price)}
                  </div>
                </div>

                <div className='flex flex-row items-center justify-between mb-3'>
                  <div className='text-sm'>Discount</div>
                  <div className='font-medium text-sm text-right'>
                    {(orderDetail.paymentFee.discount ?? 0) > 0 && '-'}
                    {formatCurrency(orderDetail.paymentFee.discount ?? 0)}
                  </div>
                </div>

                {orderDetail.paymentFee.promo && (
                  <div className='flex flex-row items-center justify-between mb-3'>
                    <div className='text-sm'>Promo Code</div>
                    <div className='font-medium text-sm text-right'>
                      {orderDetail.paymentFee.promo > 0 && '-'}
                      {formatCurrency(orderDetail.paymentFee.promo)}
                    </div>
                  </div>
                )}

                <div className='flex flex-row items-center justify-between mb-3'>
                  <div className='text-sm'>VAT</div>
                  <div className='font-medium text-sm text-right'>
                    {formatCurrency(orderDetail.paymentFee.vat ?? 0)}
                  </div>
                </div>

                <hr className='border border-border mb-3' />

                <div className='flex flex-row items-center justify-between'>
                  <div className='font-bold text-sm'>Total (inlcuding tax)</div>
                  <div className='font-bold text-sm text-right'>
                    {formatCurrency(orderDetail.paymentFee.total)}
                  </div>
                </div>
              </Card>

              <Card>
                <div className='flex flex-row gap-2 items-center'>
                  <Icon icon='calendar' size={24} />

                  <div className='flex-1'>
                    <h3 className='text-sm'>Auto Renewal</h3>
                    <div className='text-neutral text-xs'>
                      Starting from 1 Jan 2025, you&apos;ll be charged automatically every year
                      until you cancel.
                    </div>
                  </div>

                  <Checkbox
                    type={CheckboxType.Switch}
                    checked={order.isEnableAutoRenewal}
                    onCheck={() =>
                      !order.isEnableAutoRenewal
                        ? setShowAutoRenewalModal(true)
                        : toggleAutoRenewal()
                    }
                  />
                </div>
              </Card>

              <div className='flex flex-row items-center gap-2 mb-4 text-sm'>
                <Checkbox checked={order.isAgreeTnc} onCheck={toggleAgreeTnc} />
                <div>
                  I agree to the{' '}
                  <button
                    className='text-left text-secondary'
                    type='button'
                    onClick={() => onClickTnc?.(true)}
                  >
                    Terms & Condition
                  </button>
                </div>
              </div>

              <div className='flex flex-row items-center gap-2 text-sm'>
                <Checkbox checked={order.isAgreePrivacyPolicy} onCheck={toggleAgreePrivacyPolicy} />
                <div>
                  I agree to the{' '}
                  <button
                    className='text-left text-secondary'
                    type='button'
                    onClick={() => onClickPolicy?.(true)}
                  >
                    Privacy Policy
                  </button>
                </div>
              </div>

              <ConfirmModal
                title='Confirmation Auto Renewal'
                desc={
                  <>
                    <div className='mb-3'>
                      Starting from {formatDate(orderDetail.validUntil)}, you&apos;ll be charged automatically every year
                      until you cancel. Your price may change as described in Terms & Conditions.
                    </div>
                    <button
                      className='text-secondary mb-3'
                      type='button'
                      onClick={() => onClickTnc?.(false)}
                    >
                      View Terms & Conditions
                    </button>
                    <div>
                      Any promo code applied to this transaction will not be valid for the next
                      payment.
                    </div>
                  </>
                }
                open={showAutoRenewalModal}
                onCancel={() => setShowAutoRenewalModal(false)}
                onConfirm={() => {
                  setShowAutoRenewalModal(false)
                  toggleAutoRenewal()
                }}
              />
            </>
          ) : (
            <Empty small />
          )}
        </>
      )}
    </>
  )
}
