import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { LayoutType } from 'global/types/enums'
import { CONSTANT } from 'global/constant'
import { useAppDispatch } from 'store/store'
import { useGetActiveCarQuery, useLazyGetOrderDetailQuery } from 'store/subscription'
import { getOrderState, setPaymentTotal } from 'store/slices/order'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { Empty } from 'shared/components/empty'
import { OrderDetailContent } from './content'
import { OrderDetailFooter } from './footer'

export const OrderDetail = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const order = useSelector(getOrderState)

  const activeCarResult = useGetActiveCarQuery(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')

  const [getOrderDetail, orderDetailResult] = useLazyGetOrderDetailQuery()

  useEffect(() => {
    getOrderDetail({
      planId: id ?? '0',
      vin: localStorage.getItem(CONSTANT.VIN_KEY) ?? '',
      promoCode: order.promoCode
    })
  }, [])

  useEffect(() => {
    if (!orderDetailResult.isSuccess || !orderDetailResult.data) return

    dispatch(setPaymentTotal(orderDetailResult.data.paymentFee.total))
  }, [orderDetailResult.data])

  const openEnterPromoPage = () => {
    navigate(`/subscription-plan/order/promo/${id}`)
  }

  const openTncPage = (showAction: boolean) => {
    navigate(`/tnc?p=${showAction ? id : ''}`)
  }

  const openPrivacyPolicyPage = (showAction: boolean) => {
    navigate(`/privacy-policy?p=${showAction ? id : ''}`)
  }

  const canSubmit = () => order.isAgreeTnc && order.isAgreePrivacyPolicy

  const openChoosePaymentPage = () => {
    if (order.isEnableAutoRenewal) {
      navigate('/payment')
      return
    }

    navigate(`/subscription-plan/order/payment/${id}`)
  }

  return (
    <Layout
      title='Order Detail'
      type={LayoutType.HeaderFooter}
      footerComponent={
        <OrderDetailFooter
          total={order.paymentTotal}
          canSubmit={canSubmit()}
          onSubmit={openChoosePaymentPage}
        />
      }
    >
      <DataHandler {...activeCarResult}>
        {activeCarResult.data ? (
          <OrderDetailContent
            car={activeCarResult.data}
            orderDetail={orderDetailResult.data}
            onEnterPromo={openEnterPromoPage}
            onClickTnc={openTncPage}
            onClickPolicy={openPrivacyPolicyPage}
          />
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}
