import ReactModal from 'react-modal'
import Icon from 'shared/components/icon'
import type { ActionSheetProps } from 'global/types/props'

export const ActionSheet = ({
  title,
  open,
  onClose,
  scrollable = true,
  children
}: ActionSheetProps) => (
  <ReactModal
    isOpen={open}
    onRequestClose={onClose}
    contentLabel={title}
    closeTimeoutMS={300}
    bodyOpenClassName='modal-open'
    overlayClassName='modal-overlay'
    className='action-sheet'
  >
    <div className='flex flex-row items-center'>
      <button className='p-4' type='button' onClick={onClose}>
        <Icon icon='close' size={20} />
      </button>

      <h3 className='flex-1 py-4 font-heading font-medium text-lg text-center'>{title}</h3>

      <div className='p-4'>
        <div className='size-5' />
      </div>
    </div>

    <div className={scrollable ? 'scrollable-content' : ''}>{children}</div>
  </ReactModal>
)
