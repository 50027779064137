import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import { LayoutType } from 'global/types/enums'
import type { TrxHistoryParam } from 'global/types/params'
import type { TrxFilterValuesProps } from 'global/types/props'
import type { ErrorResponse, TrxHistoryListItemResponse } from 'global/types/responses'
import { useLazyGetTrxHistoryQuery } from 'store/subscription'
import { getLayoutState } from 'store/slices/layout'
import { Layout } from 'shared/components/layout'
import { DataHandler } from 'shared/components/data-handler'
import { useDebounce } from 'shared/services/utils'
import { ResultContent } from 'shared/components/result-content'
import { Spinner } from 'shared/components/spinner'
import { TrxHistoryFilter } from './filter'
import { TrxHistoryListItem } from './list-item'

export const TrxHistory = () => {
  const navigate = useNavigate()

  const { contentHeight } = useSelector(getLayoutState)

  const [getTrxHistory, trxHistoryResult] = useLazyGetTrxHistoryQuery()

  const [history, setHistory] = useState<TrxHistoryListItemResponse[]>([])

  const [showFilter, setShowFilter] = useState<boolean>(false)

  const [historyFilter, setHistoryFilter] = useState<TrxHistoryParam>({
    start: 0,
    search: ''
  })

  const [searchKey, setSearchKey] = useState<string>()

  const debounceSearchKey = useDebounce(searchKey)

  useEffect(() => {
    if (debounceSearchKey === undefined) return

    setHistoryFilter((f) => ({ ...f, start: 0, search: debounceSearchKey ?? '' }))
  }, [debounceSearchKey])

  useEffect(() => {
    getTrxHistory(historyFilter)
      .unwrap()
      .then((res) => {
        toast.dismiss()

        setHistory((prevHistory) =>
          historyFilter.start > 0 ? [...prevHistory, ...res.data] : res.data
        )
      })
      .catch((error: ErrorResponse) => {
        toast.error(error.data?.Message || 'Failed to load history.')
      })
  }, [historyFilter, getTrxHistory])

  const searchHistory = (keyword: string) => {
    setSearchKey(keyword)
  }

  const applyFilter = (filter: TrxFilterValuesProps) => {
    setShowFilter(false)

    setHistoryFilter((f) => ({
      ...f,
      start: 0,
      sortOrder: filter.sort?.toLowerCase() === 'oldest' ? 'asc' : 'desc'
    }))
  }

  const openDetailPage = (id: number) => {
    navigate(`${id}`)
  }

  return (
    <Layout
      title={L10N.LABEL.TRANSACTION_HISTORY}
      type={LayoutType.SearchHeaderWithTabFooter}
      noPadding
      onSearch={searchHistory}
      actionIcon='tune'
      onAction={() => setShowFilter(true)}
    >
      <DataHandler
        isLoading={historyFilter.start === 0 && trxHistoryResult.isFetching}
        error={trxHistoryResult.error}
      >
        {history.length ? (
          <InfiniteScroll
            dataLength={history.length}
            height={contentHeight}
            next={() => {
              setHistoryFilter((f) => ({ ...f, start: f.start + 1 }))
            }}
            hasMore={
              history.length < (trxHistoryResult.data?.recordsTotal ?? 0) &&
              (trxHistoryResult.data?.recordsFiltered ?? 0) === CONSTANT.ITEMS_PER_PAGE
            }
            loader={
              <div className='flex flex-row gap-2 items-center justify-center text-sm mb-4'>
                <Spinner />
                <div className='text-neutral'>{L10N.LABEL.LOADING}</div>
              </div>
            }
            endMessage={
              <div className='flex flex-row gap-2 items-center justify-center text-sm mb-4'>
                <div className='text-neutral'>{L10N.LABEL.NO_MORE_DATA}</div>
              </div>
            }
            refreshFunction={() => setHistoryFilter((f) => ({ ...f, start: 0 }))}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <div className='mb-4 text-center'>&#8595; {L10N.LABEL.PULL_TO_REFRESH}</div>
            }
            releaseToRefreshContent={
              <div className='mb-4 text-center'>&#8593; {L10N.LABEL.RELEASE_TO_REFRESH}</div>
            }
          >
            <div className='p-4'>
              {history.map((item, id) => (
                <TrxHistoryListItem key={id + 1} trxDetail={item} onClick={openDetailPage} />
              ))}
            </div>
          </InfiniteScroll>
        ) : historyFilter.search ? (
          <div className='h-full flex flex-col items-center justify-center p-4'>
            <ResultContent
              image='/search-not-found.png'
              title={L10N.LABEL.SEARCH_NOT_FOUND}
              desc={L10N.MESSAGE.SEARCH_NOT_FOUND_DESC}
            />
          </div>
        ) : (
          <div className='h-full flex flex-col items-center justify-center p-4'>
            <ResultContent
              image='/search-not-found.png'
              title={L10N.LABEL.TRANSACTION_NOT_FOUND}
              desc={L10N.MESSAGE.TRANSACTION_NOT_FOUND_DESC}
            />
          </div>
        )}
      </DataHandler>

      <TrxHistoryFilter
        open={showFilter}
        onClose={() => setShowFilter(false)}
        onReset={applyFilter}
        onApply={applyFilter}
      />
    </Layout>
  )
}
