import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/id'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import { AuthenticationStatus } from 'global/types/enums'
import type { SessionResponse } from 'global/types/responses'
import { useGetLocalizationQuery } from 'store/copyright'
import { useCreateSessionMutation } from 'store/session'
import { Spinner } from 'shared/components/spinner'

const TOKEN_QPARAM_KEY = 'token'
const REFRESH_TOKEN_QPARAM_KEY = 'refToken'
const LANGUAGE_QPARAM_KEY = 'lang'
const LATITUDE_QPARAM_KEY = 'lat'
const LONGITUDE_QPARAM_KEY = 'lon'
const VIN_QPARAM_KEY = 'vin'

export const Protected = () => {
  const location = useLocation()

  const { data: localization, isLoading, isSuccess, isError } = useGetLocalizationQuery()

  const [createSession, session] = useCreateSessionMutation()

  const [searchParams] = useSearchParams()

  const [authStatus, setAuthStatus] = useState<number>(AuthenticationStatus.Loading)

  useEffect(() => {
    if (!isSuccess) return

    L10N.setContent(localization)

    if (hasInitialQparams(searchParams)) {
      storeKeys(searchParams)

      L10N.setLanguage(searchParams.get(LANGUAGE_QPARAM_KEY) ?? '')
      moment.locale(searchParams.get(LANGUAGE_QPARAM_KEY) ?? CONSTANT.ENUS_LOCALE)

      // Create new session based on query parameters.
      createSession({
        token: searchParams.get(TOKEN_QPARAM_KEY) ?? '',
        refreshToken: searchParams.get(REFRESH_TOKEN_QPARAM_KEY) ?? '',
        language: searchParams.get(LANGUAGE_QPARAM_KEY) ?? '',
        latitude: Number(searchParams.get(LATITUDE_QPARAM_KEY) ?? 0),
        longitude: Number(searchParams.get(LONGITUDE_QPARAM_KEY) ?? 0)
      })
    } else {
      L10N.setLanguage(localStorage.getItem(CONSTANT.LANGUAGE_KEY) ?? '')
      moment.locale(localStorage.getItem(CONSTANT.LANGUAGE_KEY) ?? CONSTANT.ENUS_LOCALE)

      validateSession()
    }
  }, [localization, isSuccess])

  useEffect(() => {
    if (!localization) return

    validateSession()
  }, [location, searchParams])

  useEffect(() => {
    if (session.isSuccess && session.data) {
      validateSession(session.data)
    } else if (session.isError) {
      setAuthStatus(AuthenticationStatus.NotAuthenticated)
    }
  }, [session])

  const validateSession = (session?: SessionResponse) => {
    const token = session?.token ?? localStorage.getItem(CONSTANT.TOKEN_KEY)
    const sessionId = session?.sessionId ?? localStorage.getItem(CONSTANT.SESSION_ID_KEY)

    if (session) {
      if (token) localStorage.setItem(CONSTANT.TOKEN_KEY, token)
      if (sessionId) localStorage.setItem(CONSTANT.SESSION_ID_KEY, sessionId)
    }

    setAuthStatus(Number(Boolean(token) && Boolean(sessionId)))
  }

  if (isLoading) {
    return (
      <div className='flex items-center justify-center h-screen text-lg'>
        <div className='flex flex-row gap-1 items-center'>
          <Spinner />
          <span>Initializing...</span>
        </div>
      </div>
    )
  } else if (isError) {
    return (
      <div className='flex items-center justify-center h-screen text-lg'>
        <span>🚫 Initialization failed.</span>
      </div>
    )
  }

  switch (authStatus) {
    case AuthenticationStatus.Loading:
      return (
        <div className='flex items-center justify-center h-screen text-lg'>
          <div className='flex flex-row gap-1 items-center'>
            <Spinner />
            <span>Validating...</span>
          </div>
        </div>
      )

    case AuthenticationStatus.Authenticated:
      if (location.pathname === '/') return <Navigate to={'/home'} />

      return <Outlet />

    default:
      return (
        <div className='flex items-center justify-center h-screen text-lg'>
          <span>🚫 Authentication failed.</span>
        </div>
      )
  }
}

const hasInitialQparams = (searchParams: URLSearchParams): boolean => {
  const requiredKeys = [
    TOKEN_QPARAM_KEY,
    REFRESH_TOKEN_QPARAM_KEY,
    LANGUAGE_QPARAM_KEY,
    LATITUDE_QPARAM_KEY,
    LONGITUDE_QPARAM_KEY,
    VIN_QPARAM_KEY
  ]

  const isValid: boolean = requiredKeys.reduce((a, b) => a && Boolean(searchParams.get(b)), true)

  return isValid
}

/**
 * Store keys from query parameters into local storage.
 * @param searchParams Query parameters.
 */
const storeKeys = (searchParams: URLSearchParams) => {
  const language = searchParams.get(CONSTANT.LANGUAGE_KEY) || CONSTANT.ENUS_LOCALE
  const vin = searchParams.get(CONSTANT.VIN_KEY)

  if (language) localStorage.setItem(CONSTANT.LANGUAGE_KEY, language)
  if (vin) localStorage.setItem(CONSTANT.VIN_KEY, vin)
}
