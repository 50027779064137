import { decode } from 'html-entities'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { L10N } from 'global/localization'
import { LayoutType } from 'global/types/enums'
import { useGetPrivacyPolicyQuery } from 'store/copyright'
import { useAppDispatch } from 'store/store'
import { setAgreePrivacyPolicy } from 'store/slices/order'
import { Layout } from 'shared/components/layout'
import { DataHandler } from 'shared/components/data-handler'
import { PrivacyPolicyFooter } from './footer'

const PACKAGE_QPARAM_KEY = 'p'

export const PrivacyPolicy = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const ppData = useGetPrivacyPolicyQuery()

  const agree = () => {
    const packageId = searchParams.get(PACKAGE_QPARAM_KEY)

    dispatch(setAgreePrivacyPolicy(true))

    navigate(`/subscription-plan/order/${packageId}`)
  }

  return (
    <Layout
      title={L10N.LABEL.PRIVACY_POLICY}
      type={searchParams.get(PACKAGE_QPARAM_KEY) ? LayoutType.HeaderFooter : undefined}
    >
      <DataHandler {...ppData}>
        <div
          className='prose overflow-auto mb-4 pb-4'
          dangerouslySetInnerHTML={{ __html: decode(ppData.data?.content ?? '') }}
        />
        {searchParams.get(PACKAGE_QPARAM_KEY) ? <PrivacyPolicyFooter onClick={agree} /> : undefined}
      </DataHandler>
    </Layout>
  )
}
