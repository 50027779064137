import { API } from 'global/api'
import type { LanguageResponse } from 'global/types/responses'
import { CONSTANT } from 'global/constant'
import { api, headers } from './api'

const languageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query<LanguageResponse[], void>({
      query: () => ({
        url: API.URL_GET_LANGUAGE_LIST,
        method: 'GET',
        headers: headers()
      }),
      transformResponse: (baseQueryReturnValue: Omit<LanguageResponse, 'image'>[]) =>
        baseQueryReturnValue.map((r) => {
          let image = ''

          switch (r.value) {
            case CONSTANT.ID_LOCALE:
              image = '/flag-id.png'
              break

            case CONSTANT.ENUS_LOCALE:
              image = '/flag-gb.png'
              break
          }

          return { ...r, image }
        })
    })
  })
})

export const { useGetLanguagesQuery } = languageApi
