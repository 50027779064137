import { useSelector } from 'react-redux'
import { L10N } from 'global/localization'
import type { DataHandlerProps } from 'global/types/props'
import { getLayoutState } from 'store/slices/layout'
import { Spinner } from 'shared/components/spinner'
import { ResultContent } from 'shared/components/result-content'

export const DataHandler = ({
  isLoading,
  error,
  onReload,
  noAnimation = false,
  children
}: DataHandlerProps) => {
  const { noPadding } = useSelector(getLayoutState)

  if (isLoading)
    return (
      <div className='h-full flex items-center justify-center'>
        <Spinner size='large' />
      </div>
    )

  if (error)
    return (
      <div className='h-full flex flex-col items-center justify-center animate-fadeIn'>
        <ResultContent
          image='/not-found.png'
          title={L10N.LABEL.DATA_NOT_FOUND}
          useSmallAction
          actionText={L10N.LABEL.RELOAD}
          onAction={onReload}
        />
      </div>
    )

  return (
    <div
      id='dataHandler'
      className={[
        'h-full overflow-y-auto',
        noAnimation ? '' : 'animate-fadeIn',
        !noPadding ? 'p-4' : ''
      ].join(' ')}
    >
      {children}
    </div>
  )
}
